import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"

import App from './pages/App';
import Login from './pages/Login';
import GetUser from "./pages/User/Update/GetUser";
import Home from "./pages/User/Home";
import UpdateInfo from "./pages/User/Update/UpdateInfo";
import SetInfo from "./pages/User/Update/SetInfo";
import ContadorFluxo from "./contadorFluxo";
import PoliticaPrivacidade from "./PoliticaPrivacidade";
import Certificado from "./pages/EducPatri/certificado";
import Verificador from "./pages/Verificador";
import EditUser from "./pages/EditUser";

function RouteSite() {

  return (

    <Router>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/sy/*' element={<App />} />
        <Route path='/certificado' element={<Certificado />} />
        <Route path='/home' element={<Home />} />
        <Route path='/info' element={<GetUser />} />
        <Route path='/update' element={<UpdateInfo />} />
        <Route path='/setinfo' element={<SetInfo />} />
        <Route path='/verificacao' element={<Verificador />} />
        <Route path='/contador-fluxo' element={<ContadorFluxo />} />
        <Route path='/politica-privacidade' element={<PoliticaPrivacidade />} />
        <Route path='/edit-user' element={<EditUser />} />
      </Routes>
    </Router>

  );
}

export default RouteSite;