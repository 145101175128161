import React, { useState, useEffect } from "react";
import './styles.css'
import { db } from '../../services/firebaseConnection'
import { ref as ref_database, set, child, get } from "firebase/database";
import Select from 'react-select'

import { PieChart, Pie, Cell, Legend, BarChart, XAxis, YAxis, Bar, LineChart, Line, AreaChart, Area, ComposedChart, Label } from 'recharts';

function Dashboard() {

    const [campoSel, setCampoSel] = useState("")
    const [projeto, setProjeto] = useState("");
    const [keysCount, setKeysCount] = useState("");
    const [dataCharts, setDataCharts] = useState()
    const [selectPJ, setSelectPJ] = useState(true)

    const options = campoSel

    const optionsProjeto = campoSel

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AD00FF', '#FF00A7', '#00FFD4'];

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    const dataCampo = () => {
        const dbRef = ref_database(db);

        get(child(dbRef, 'backup/' + projeto + '/dadosPV')).then((snapshot) => {
            if (snapshot.exists()) {
                const dados = snapshot.val()

                //console.log(dados)

                /* #region ENTORNO */

                //VEGETAÇÃO
                const agVege = Object.entries(dados).map((x) => {
                    if (x[1]) {
                        return x[1].vegetacao
                    }
                }).filter(Boolean)

                const countsVeg = agVege.reduce((acc, curr) => {
                    if (acc[curr]) {
                        acc[curr]++;
                    } else {
                        acc[curr] = 1;
                    }
                    return acc;
                }, {});

                const grouVeg = Object.entries(countsVeg).map(([name, value]) => ({ name, value }));
                grouVeg.sort((a, b) => b[1] - a[1]);

                //POSIÇÃO TOPOGRAFICA
                const agPTop = Object.entries(dados).map((x) => {
                    if (x[1]) {
                        return x[1].posicaoTop
                    }
                }).filter(Boolean)

                const countsTop = agPTop.reduce((acc, curr) => {
                    if (acc[curr]) {
                        acc[curr]++;
                    } else {
                        acc[curr] = 1;
                    }
                    return acc;
                }, {});

                const grouTop = Object.entries(countsTop).map(([name, value]) => ({ name, value }));
                grouTop.sort((a, b) => b[1] - a[1]);

                //FEIÇÃO DE RELEVO
                const agFRev = Object.entries(dados).map((x) => {
                    if (x[1]) {
                        return x[1].feicRelevo
                    }
                }).filter(Boolean)

                const countsRev = agFRev.reduce((acc, curr) => {
                    if (acc[curr]) {
                        acc[curr]++;
                    } else {
                        acc[curr] = 1;
                    }
                    return acc;
                }, {});

                const grouRev = Object.entries(countsRev).map(([name, value]) => ({ name, value }));
                grouRev.sort((a, b) => b[1] - a[1]);

                //DECLIVIDADE
                const agDec = Object.entries(dados).map((x) => {
                    if (x[1]) {
                        return x[1].declividade
                    }
                }).filter(Boolean)

                const countsDec = agDec.reduce((acc, curr) => {
                    if (acc[curr]) {
                        acc[curr]++;
                    } else {
                        acc[curr] = 1;
                    }
                    return acc;
                }, {});

                const grouDec = Object.entries(countsDec).map(([name, value]) => ({ name, value }));
                grouDec.sort((a, b) => b[1] - a[1]);

                /* #endregion */

                const data = {
                    vegetacao: grouVeg, posicTop: grouTop, feicRelevo: grouRev,
                    declividade: grouDec, encerramento: [], pedologia: [],
                    pedTB: [], pacotes: [], intra: []
                }
                console.log(grouVeg)
                setDataCharts(data)

            } else {
                console.log("No data available");
            }
        }).catch((error) => {
            console.error(error);
        });

    }

    const dataCampoPT = () => {
        const dbRef = ref_database(db);

        get(child(dbRef, 'backup/' + projeto + '/dados')).then((snapshot) => {
            if (snapshot.exists()) {
                const dados = snapshot.val()

                /* #region ENTORNO */

                //VEGETAÇÃO
                const agVege = Object.entries(dados).map((x) => {
                    if (x[1].entorno) {
                        return x[1].entorno.vegetacao
                    }
                }).filter(Boolean)

                const countsVeg = agVege.reduce((acc, curr) => {
                    if (acc[curr]) {
                        acc[curr]++;
                    } else {
                        acc[curr] = 1;
                    }
                    return acc;
                }, {});

                const grouVeg = Object.entries(countsVeg).map(([name, value]) => ({ name, value }));
                grouVeg.sort((a, b) => b[1] - a[1]);

                //POSIÇÃO TOPOGRAFICA
                const agPTop = Object.entries(dados).map((x) => {
                    if (x[1].entorno) {
                        return x[1].entorno.posicaoTop
                    }
                }).filter(Boolean)

                const countsTop = agPTop.reduce((acc, curr) => {
                    if (acc[curr]) {
                        acc[curr]++;
                    } else {
                        acc[curr] = 1;
                    }
                    return acc;
                }, {});

                const grouTop = Object.entries(countsTop).map(([name, value]) => ({ name, value }));
                grouTop.sort((a, b) => b[1] - a[1]);

                //FEIÇÃO DE RELEVO
                const agFRev = Object.entries(dados).map((x) => {
                    if (x[1].entorno) {
                        return x[1].entorno.feicRelevo
                    }
                }).filter(Boolean)

                const countsRev = agFRev.reduce((acc, curr) => {
                    if (acc[curr]) {
                        acc[curr]++;
                    } else {
                        acc[curr] = 1;
                    }
                    return acc;
                }, {});

                const grouRev = Object.entries(countsRev).map(([name, value]) => ({ name, value }));
                grouRev.sort((a, b) => b[1] - a[1]);

                //DECLIVIDADE
                const agDec = Object.entries(dados).map((x) => {
                    if (x[1].entorno) {
                        return x[1].entorno.declividade
                    }
                }).filter(Boolean)

                const countsDec = agDec.reduce((acc, curr) => {
                    if (acc[curr]) {
                        acc[curr]++;
                    } else {
                        acc[curr] = 1;
                    }
                    return acc;
                }, {});

                const grouDec = Object.entries(countsDec).map(([name, value]) => ({ name, value }));
                grouDec.sort((a, b) => b[1] - a[1]);

                /* #endregion */

                /* #region ENCERRAMENTO */
                const agEnc = Object.entries(dados).map((x) => {
                    if (x[1].entorno && x[1].encerramento) {
                        return x[1].encerramento.encerramento
                    }
                }).filter(Boolean)

                const countsEnc = agEnc.reduce((acc, curr) => {
                    if (acc[curr]) {
                        acc[curr]++;
                    } else {
                        acc[curr] = 1;
                    }
                    return acc;
                }, {});

                const grouEnc = Object.entries(countsEnc).map(([name, value]) => ({ name, value }));
                grouEnc.sort((a, b) => b[1] - a[1]);
                /* #endregion */

                /* #region INTRANSPONIBILIDADE */
                const agInt = Object.entries(dados).map((x) => {
                    if (x[1].intransponibilidade) {
                        return x[1].intransponibilidade.motivo
                    }
                }).filter(Boolean)


                const countsInt = agInt.reduce((acc, curr) => {
                    if (acc[curr]) {
                        acc[curr]++;
                    } else {
                        acc[curr] = 1;
                    }
                    return acc;
                }, {});

                const grouInt = Object.entries(countsInt).map(([name, value]) => ({ name, value }));
                grouInt.sort((a, b) => b[1] - a[1]);
                /* #endregion */

                /* #region PEDOLOGIA */
                const agPed = Object.entries(dados).map((x) => {
                    if (x[1].pedologia) {
                        const cs = Object.keys(x[1].pedologia)
                            .filter(key => key !== "pt")
                            .sort((a, b) => parseInt(a) - parseInt(b))
                        const ultimoElemento = cs[cs.length - 1];

                        return ultimoElemento
                    }
                }).filter(Boolean)

                agPed.sort((a, b) => {
                    const numA = parseInt(a.split('-')[1]);
                    const numB = parseInt(b.split('-')[1]);
                    return numA - numB;
                });

                const countsPed = agPed.reduce((acc, curr) => {
                    if (acc[curr]) {
                        acc[curr]++;
                    } else {
                        acc[curr] = 1;
                    }
                    return acc;
                }, {});

                const grouPed = Object.entries(countsPed).map(([name, value]) => ({ name, value }));
                grouPed.sort((a, b) => b[1] - a[1]);
                /* #endregion */

                /* #region PEDOLOGIA - PROFUNDIDADE */
                const agPedTB = Object.entries(dados).map((x) => {


                    if (x[1].pedologia) {
                        const dataPedolo = Object.keys(x[1].pedologia).filter(r => !r.includes("pt"))

                        //const cs = Object.keys(x[1].pedologia).sort((a, b) => parseInt(a) - parseInt(b))

                        const cs = dataPedolo.sort((a, b) => parseInt(a) - parseInt(b))
                        const ultimoElemento = cs[cs.length - 1];
                        const cotaUlt = parseInt(ultimoElemento) + 10

                        return cotaUlt
                    }
                }).filter(Boolean)

                const soma = agPedTB.reduce((acc, curr) => acc + curr, 0);
                const media = parseFloat((soma / agPedTB.length).toFixed(2))

                const gpPedTB = agPedTB
                    .sort((a, b) => a - b)
                    .map(x => {
                        return { name: 1, value: x, media: media }
                    })
                /* #endregion */

                /* #region PEDOLOGIA - MÉDIA - PACOTES */
                const agPedMP = Object.entries(dados).map((x) => {
                    if (x[1].pedologia) {
                        const cs = Object.values(x[1].pedologia)
                        return cs
                    }
                }).filter(Boolean)

                const combinedArray = agPedMP.reduce((result, subArray) => result.concat(subArray), []);

                /* #region MAP DO NÍVEIS */
                const cm10 = combinedArray.map(r => {
                    if (r.nivel === '0-10') {
                        return r.coloracao
                    }
                }).filter(Boolean)

                const counts10 = cm10.reduce((acc, curr) => {
                    if (acc[curr]) {
                        acc[curr]++;
                    } else {
                        acc[curr] = 1;
                    }
                    return acc;
                }, {});
                const grou10 = Object.entries(counts10).map(([name, value]) => {
                    const percent = parseFloat((value / cm10.length) * 100).toFixed(2)
                    return { [name]: percent };
                });
                const obj10 = grou10.reduce((resultado, item) => {
                    const chave = Object.keys(item)[0];
                    const valor = Object.values(item)[0];
                    resultado[chave] = valor;
                    return resultado;
                }, {});
                const nGrop10 = [{ ...obj10, name: '0-10' }]

                const cm20 = combinedArray.map(r => {
                    if (r.nivel === '10-20') {
                        return r.coloracao
                    }
                }).filter(Boolean)

                const counts20 = cm20.reduce((acc, curr) => {
                    if (acc[curr]) {
                        acc[curr]++;
                    } else {
                        acc[curr] = 1;
                    }
                    return acc;
                }, {});
                const grou20 = Object.entries(counts20).map(([name, value]) => {
                    const percent = parseFloat((value / cm20.length) * 100).toFixed(2)
                    return { [name]: percent };
                });
                const obj20 = grou20.reduce((resultado, item) => {
                    const chave = Object.keys(item)[0];
                    const valor = Object.values(item)[0];
                    resultado[chave] = valor;
                    return resultado;
                }, {});
                const nGrop20 = [{ ...obj20, name: '10-20' }]

                const cm30 = combinedArray.map(r => {
                    if (r.nivel === '20-30') {
                        return r.coloracao
                    }
                }).filter(Boolean)

                const counts30 = cm30.reduce((acc, curr) => {
                    if (acc[curr]) {
                        acc[curr]++;
                    } else {
                        acc[curr] = 1;
                    }
                    return acc;
                }, {});
                const grou30 = Object.entries(counts30).map(([name, value]) => {
                    const percent = parseFloat((value / cm30.length) * 100).toFixed(2)
                    return { [name]: percent };
                });
                const obj30 = grou30.reduce((resultado, item) => {
                    const chave = Object.keys(item)[0];
                    const valor = Object.values(item)[0];
                    resultado[chave] = valor;
                    return resultado;
                }, {});
                const nGrop30 = [{ ...obj30, name: '20-30' }]

                const cm40 = combinedArray.map(r => {
                    if (r.nivel === '30-40') {
                        return r.coloracao
                    }
                }).filter(Boolean)

                const counts40 = cm40.reduce((acc, curr) => {
                    if (acc[curr]) {
                        acc[curr]++;
                    } else {
                        acc[curr] = 1;
                    }
                    return acc;
                }, {});
                const grou40 = Object.entries(counts40).map(([name, value]) => {
                    const percent = parseFloat((value / cm40.length) * 100).toFixed(2)
                    return { [name]: percent };
                });
                const obj40 = grou40.reduce((resultado, item) => {
                    const chave = Object.keys(item)[0];
                    const valor = Object.values(item)[0];
                    resultado[chave] = valor;
                    return resultado;
                }, {});
                const nGrop40 = [{ ...obj40, name: '30-40' }]

                const cm50 = combinedArray.map(r => {
                    if (r.nivel === '40-50') {
                        return r.coloracao
                    }
                }).filter(Boolean)

                const counts50 = cm50.reduce((acc, curr) => {
                    if (acc[curr]) {
                        acc[curr]++;
                    } else {
                        acc[curr] = 1;
                    }
                    return acc;
                }, {});
                const grou50 = Object.entries(counts50).map(([name, value]) => {
                    const percent = parseFloat((value / cm50.length) * 100).toFixed(2)
                    return { [name]: percent };
                });
                const obj50 = grou50.reduce((resultado, item) => {
                    const chave = Object.keys(item)[0];
                    const valor = Object.values(item)[0];
                    resultado[chave] = valor;
                    return resultado;
                }, {});
                const nGrop50 = [{ ...obj50, name: '40-50' }]

                const cm60 = combinedArray.map(r => {
                    if (r.nivel === '50-60') {
                        return r.coloracao
                    }
                }).filter(Boolean)

                const counts60 = cm60.reduce((acc, curr) => {
                    if (acc[curr]) {
                        acc[curr]++;
                    } else {
                        acc[curr] = 1;
                    }
                    return acc;
                }, {});
                const grou60 = Object.entries(counts60).map(([name, value]) => {
                    const percent = parseFloat((value / cm60.length) * 100).toFixed(2)
                    return { [name]: percent };
                });
                const obj60 = grou60.reduce((resultado, item) => {
                    const chave = Object.keys(item)[0];
                    const valor = Object.values(item)[0];
                    resultado[chave] = valor;
                    return resultado;
                }, {});
                const nGrop60 = [{ ...obj60, name: '50-60' }]

                const cm70 = combinedArray.map(r => {
                    if (r.nivel === '60-70') {
                        return r.coloracao
                    }
                }).filter(Boolean)

                const counts70 = cm70.reduce((acc, curr) => {
                    if (acc[curr]) {
                        acc[curr]++;
                    } else {
                        acc[curr] = 1;
                    }
                    return acc;
                }, {});
                const grou70 = Object.entries(counts70).map(([name, value]) => {
                    const percent = parseFloat((value / cm70.length) * 100).toFixed(2)
                    return { [name]: percent };
                });
                const obj70 = grou70.reduce((resultado, item) => {
                    const chave = Object.keys(item)[0];
                    const valor = Object.values(item)[0];
                    resultado[chave] = valor;
                    return resultado;
                }, {});
                const nGrop70 = [{ ...obj70, name: '60-70' }]

                const cm80 = combinedArray.map(r => {
                    if (r.nivel === '70-80') {
                        return r.coloracao
                    }
                }).filter(Boolean)

                const counts80 = cm80.reduce((acc, curr) => {
                    if (acc[curr]) {
                        acc[curr]++;
                    } else {
                        acc[curr] = 1;
                    }
                    return acc;
                }, {});
                const grou80 = Object.entries(counts80).map(([name, value]) => {
                    const percent = parseFloat((value / cm80.length) * 100).toFixed(2)
                    return { [name]: percent };
                });
                const obj80 = grou80.reduce((resultado, item) => {
                    const chave = Object.keys(item)[0];
                    const valor = Object.values(item)[0];
                    resultado[chave] = valor;
                    return resultado;
                }, {});
                const nGrop80 = [{ ...obj80, name: '70-80' }]

                const cm90 = combinedArray.map(r => {
                    if (r.nivel === '80-90') {
                        return r.coloracao
                    }
                }).filter(Boolean)

                const counts90 = cm90.reduce((acc, curr) => {
                    if (acc[curr]) {
                        acc[curr]++;
                    } else {
                        acc[curr] = 1;
                    }
                    return acc;
                }, {});
                const grou90 = Object.entries(counts90).map(([name, value]) => {
                    const percent = parseFloat((value / cm90.length) * 100).toFixed(2)
                    return { [name]: percent };
                });
                const obj90 = grou90.reduce((resultado, item) => {
                    const chave = Object.keys(item)[0];
                    const valor = Object.values(item)[0];
                    resultado[chave] = valor;
                    return resultado;
                }, {});
                const nGrop90 = [{ ...obj90, name: '80-90' }]

                const cm100 = combinedArray.map(r => {
                    if (r.nivel === '90-100') {
                        return r.coloracao
                    }
                }).filter(Boolean)

                const counts100 = cm100.reduce((acc, curr) => {
                    if (acc[curr]) {
                        acc[curr]++;
                    } else {
                        acc[curr] = 1;
                    }
                    return acc;
                }, {});
                const grou100 = Object.entries(counts100).map(([name, value]) => {
                    const percent = parseFloat((value / cm100.length) * 100).toFixed(2)
                    return { [name]: percent };
                });
                const obj100 = grou100.reduce((resultado, item) => {
                    const chave = Object.keys(item)[0];
                    const valor = Object.values(item)[0];
                    resultado[chave] = valor;
                    return resultado;
                }, {});
                const nGrop100 = [{ ...obj100, name: '90-100' }]

                const cm110 = combinedArray.map(r => {
                    if (r.nivel === '100-110') {
                        return r.coloracao
                    }
                }).filter(Boolean)

                const counts110 = cm110.reduce((acc, curr) => {
                    if (acc[curr]) {
                        acc[curr]++;
                    } else {
                        acc[curr] = 1;
                    }
                    return acc;
                }, {});
                const grou110 = Object.entries(counts110).map(([name, value]) => {
                    const percent = parseFloat((value / cm110.length) * 100).toFixed(2)
                    return { [name]: percent };
                });
                const obj110 = grou110.reduce((resultado, item) => {
                    const chave = Object.keys(item)[0];
                    const valor = Object.values(item)[0];
                    resultado[chave] = valor;
                    return resultado;
                }, {});
                const nGrop110 = [{ ...obj110, name: '100-110' }]

                const cm120 = combinedArray.map(r => {
                    if (r.nivel === '110-120') {
                        return r.coloracao
                    }
                }).filter(Boolean)

                const counts120 = cm120.reduce((acc, curr) => {
                    if (acc[curr]) {
                        acc[curr]++;
                    } else {
                        acc[curr] = 1;
                    }
                    return acc;
                }, {});
                const grou120 = Object.entries(counts120).map(([name, value]) => {
                    const percent = parseFloat((value / cm120.length) * 100).toFixed(2)
                    return { [name]: percent };
                });
                const obj120 = grou120.reduce((resultado, item) => {
                    const chave = Object.keys(item)[0];
                    const valor = Object.values(item)[0];
                    resultado[chave] = valor;
                    return resultado;
                }, {});
                const nGrop120 = [{ ...obj120, name: '110-120' }]

                const cm130 = combinedArray.map(r => {
                    if (r.nivel === '120-130') {
                        return r.coloracao
                    }
                }).filter(Boolean)

                const counts130 = cm130.reduce((acc, curr) => {
                    if (acc[curr]) {
                        acc[curr]++;
                    } else {
                        acc[curr] = 1;
                    }
                    return acc;
                }, {});
                const grou130 = Object.entries(counts130).map(([name, value]) => {
                    const percent = parseFloat((value / cm130.length) * 100).toFixed(2)
                    return { [name]: percent };
                });
                const obj130 = grou130.reduce((resultado, item) => {
                    const chave = Object.keys(item)[0];
                    const valor = Object.values(item)[0];
                    resultado[chave] = valor;
                    return resultado;
                }, {});
                const nGrop130 = [{ ...obj130, name: '120-130' }]
                /* #endregion */

                const gpPacotes = [...nGrop10, ...nGrop20, ...nGrop30, ...nGrop40, ...nGrop50, ...nGrop50, ...nGrop60, ...nGrop70, ...nGrop80, ...nGrop90, ...nGrop100, ...nGrop110, ...nGrop120, ...nGrop130]
                /* #endregion */

                const data = {
                    vegetacao: grouVeg, posicTop: grouTop, feicRelevo: grouRev,
                    declividade: grouDec, encerramento: grouEnc, pedologia: grouPed,
                    pedTB: gpPedTB, pacotes: gpPacotes, intra: grouInt
                }
                setDataCharts(data)

            } else {
                console.log("No data available");
            }
        }).catch((error) => {
            console.error(error);
        });

    }

    useEffect(() => {
        const dbRef = ref_database(db);

        get(child(dbRef, 'projetos/list')).then((snapshot) => {
            if (snapshot.exists()) {
                const dados = snapshot.val()

                var arrProjs = []
                dados.map(x => { arrProjs.push(x.projeto) })
                function myFunction(num) {
                    return { value: num, label: num };
                }
                const map1 = arrProjs.map(myFunction);
                setCampoSel(map1)

            } else {
                console.log("No data available");
            }
        }).catch((error) => {
            console.error(error);
        });

        const projeto = "AIPA - Fazenda Bate Bate - Pontos de Vistoria"

        get(child(dbRef, 'projetos/' + projeto + "/campo/QMjMs3h7gLbjVDAuVKvYt5fMmRi2/dados/pv")).then((snapshot) => {
            if (snapshot.exists()) {
                const dados = snapshot.val()

                console.log(Object.keys(dados))

            } else {
                console.log("No data available");
            }
        })

        /*get(child(dbRef, 'userperm/')).then((snapshot) => {
            if (snapshot.exists()) {
                const dados = snapshot.val()
                const arrCampoUsers = []
                for (const [key, value] of Object.entries(dados)) {
                    const campo = { user: value.uid, projeto: value.projeto }
                    arrCampoUsers.push(campo)
                }
                setCampoUsers(arrCampoUsers)
                //console.log(arrCampoUsers)
     
            } else {
                console.log("No data available");
            }
        }).catch((error) => {
            console.error(error);
        });*/

        /*get(child(dbRef, 'projetos/' + projetoAcom)).then((snapshot) => {
            if (snapshot.exists()) {
                const dados = snapshot.val()
                setCamposGeral(dados)
            } else {
                console.log("No data available");
            }
        }).catch((error) => {
            console.error(error);
        });*/

    }, [])

    const xAxisTicks = [0, 20, 40, 60, 80, 100]
    const yAxisProf = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130]

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file && file.type === "application/json") {
            const reader = new FileReader();

            reader.onload = (e) => {
                try {
                    const parsedData = JSON.parse(e.target.result);
                    const dataCampo = "AIPA - Fazenda Bate Bate"

                    //console.log(Object.keys(parsedData[dataCampo].dados).length)
                    //console.log(parsedData.dataUser.nome)

                    //console.log(Object.keys(parsedData.dados).length)

                    const dados = Object.keys(parsedData.dados)

                    const pt = "PT -1052"

                    console.log(dados.filter(x => x === pt))

                    //const dados = Object.keys(parsedData[dataCampo].dados)

                    //const pt = "PT -056"

                    //console.log(dados.filter(x => x === pt))

                    // Aqui você pode tratar os dados como quiser
                } catch (error) {
                    console.error("Erro ao processar JSON:", error);
                }
            };
            reader.readAsText(file);
        } else {
            console.error("Por favor, selecione um arquivo JSON válido.");
        }
    }

    return (

        <div style={{ margin: '20px' }}>
            <div>
                {selectPJ &&
                    <div>
                        <a className="header_txt">Dashboard </a>
                        <div className="card_select">
                            <Select
                                options={options}
                                placeholder="Projeto"
                                className="select_proj_d"
                                onChange={(e) => { setProjeto(e.value); setKeysCount("") }}
                            />

                            <div className="car_cadastro">
                                <button onClick={() => dataCampoPT() || setSelectPJ(!selectPJ)} className="btn_cd">Abrir</button>
                            </div>
                        </div>
                    </div>
                }

                <div>
                    <input type="file" accept="application/json" onChange={handleFileChange} />
                </div>

                {dataCharts &&
                    <div>
                        <div className="card_herder_back">
                            <a className="header_txt">Dashboard - {projeto}</a>
                            <button onClick={() => setSelectPJ(!selectPJ) || setDataCharts("")} className="btn_back_db">Voltar</button>
                        </div>

                        <div style={{ height: '85vh', width: '100%', overflowY: 'scroll', marginTop: '15px' }}>

                            <div className="card_pie">

                                <div className="grid_item">
                                    <a className="text_tl">Vegetação</a>
                                    <PieChart width={450} height={330}>
                                        <Pie
                                            data={dataCharts.vegetacao}
                                            dataKey="value"
                                            nameKey="name"
                                            cx="50%"
                                            cy="50%"
                                            innerRadius={70}
                                            outerRadius={100}
                                            fill="#82ca9d"
                                            label={({ percent, value }) => `${(value)} | ${(percent * 100).toFixed(2)}% `}
                                            minAngle={5}
                                        >
                                            {dataCharts.vegetacao.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                            ))}
                                        </Pie>
                                        <Legend />
                                    </PieChart>
                                </div>

                                <div className="grid_item">
                                    <a className="text_tl">Posição topográfica</a>
                                    <PieChart width={450} height={300}>
                                        <Pie
                                            data={dataCharts.posicTop}
                                            dataKey="value"
                                            nameKey="name"
                                            cx="50%"
                                            cy="50%"
                                            innerRadius={70}
                                            outerRadius={100}
                                            fill="#82ca9d"
                                            label={({ percent, value }) => `${(value)} | ${(percent * 100).toFixed(2)}% `}
                                            minAngle={5}
                                        >
                                            {dataCharts.posicTop.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                            ))}
                                        </Pie>
                                        <Legend />
                                    </PieChart>
                                </div>

                                <div className="grid_item">
                                    <a className="text_tl">Feição de relevo</a>
                                    <PieChart width={450} height={330}>
                                        <Pie
                                            data={dataCharts.feicRelevo}
                                            dataKey="value"
                                            nameKey="name"
                                            cx="50%"
                                            cy="50%"
                                            innerRadius={70}
                                            outerRadius={100}
                                            fill="#82ca9d"
                                            label={({ percent, value }) => `${(value)} | ${(percent * 100).toFixed(2)}% `}
                                            minAngle={7}
                                        >
                                            {dataCharts.feicRelevo.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                            ))}
                                        </Pie>
                                        <Legend />
                                    </PieChart>
                                </div>

                                <div className="grid_item">
                                    <a className="text_tl">Declividade</a>
                                    <PieChart width={450} height={300}>
                                        <Pie
                                            data={dataCharts.declividade}
                                            dataKey="value"
                                            nameKey="name"
                                            cx="50%"
                                            cy="50%"
                                            innerRadius={70}
                                            outerRadius={100}
                                            fill="#82ca9d"
                                            label={({ percent, value }) => `${(value)} | ${(percent * 100).toFixed(2)}% `}
                                            minAngle={5}
                                        >
                                            {dataCharts.declividade.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                            ))}
                                        </Pie>
                                        <Legend />
                                    </PieChart>
                                </div>

                                {dataCharts.encerramento[0] &&
                                    <div className="grid_item">
                                        <a className="text_tl">Encerramento</a>
                                        <PieChart width={450} height={330}>
                                            <Pie
                                                data={dataCharts.encerramento}
                                                dataKey="value"
                                                nameKey="name"
                                                cx="50%"
                                                cy="50%"
                                                innerRadius={70}
                                                outerRadius={100}
                                                fill="#82ca9d"
                                                label={({ percent, value }) => `${(value)} | ${(percent * 100).toFixed(2)}% `}
                                            >
                                                {dataCharts.encerramento.map((entry, index) => (
                                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                ))}
                                            </Pie>
                                            <Legend />
                                        </PieChart>
                                    </div>
                                }

                                {dataCharts.intra[0] &&
                                    <div className="grid_item">
                                        <a className="text_tl">Intransponibilidade</a>
                                        <PieChart width={450} height={330}>
                                            <Pie
                                                data={dataCharts.intra}
                                                dataKey="value"
                                                nameKey="name"
                                                cx="50%"
                                                cy="50%"
                                                innerRadius={70}
                                                outerRadius={100}
                                                fill="#82ca9d"
                                                label={({ percent, value }) => `${(value)} | ${(percent * 100).toFixed(2)}% `}
                                            >
                                                {dataCharts.intra.map((entry, index) => (
                                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                ))}
                                            </Pie>
                                            <Legend />
                                        </PieChart>
                                    </div>
                                }

                            </div>

                            {console.log(dataCharts.pedTB)}

                            <div className="card_bar">
                                <a className="text_tl">Coloração por pacote estratigráfico</a>
                                <BarChart
                                    width={650}
                                    height={420}
                                    data={dataCharts.pacotes}
                                    margin={{
                                        top: 5,
                                        right: 20,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                    layout="vertical"
                                >
                                    <XAxis type="number" domain={[0, 100]} ticks={xAxisTicks} height={40}>
                                        <Label value="Coloração do pacote (%)" offset={10} dy={10} position="insideBottom" />
                                    </XAxis>
                                    <YAxis dataKey="name" type="category" interval={0} />
                                    <Legend verticalAlign="top" />
                                    <Bar dataKey="Branca" stackId="a" fill="#e8e4d6" />
                                    <Bar dataKey="Cinza claro" stackId="a" fill="#d4ccb6" />
                                    <Bar dataKey="Cinza escuro" stackId="a" fill="#b5ab89" />
                                    <Bar dataKey="Vermelho" stackId="a" fill="#b24417" />
                                    <Bar dataKey="Avermelhado" stackId="a" fill="#983315" />
                                    <Bar dataKey="Amarelo" stackId="a" fill="#f2d770" />
                                    <Bar dataKey="Amarelado" stackId="a" fill="#e8b71d" />
                                    <Bar dataKey="Laranja" stackId="a" fill="#db890a" />
                                    <Bar dataKey="Alaranjado" stackId="a" fill="#ca901a" />
                                    <Bar dataKey="Marrom claro" stackId="a" fill="#715c43" />
                                    <Bar dataKey="Marrom escuro" stackId="a" fill="#73441e" />
                                    <Bar dataKey="Preta" stackId="a" fill="#1f1411" />
                                </BarChart>
                            </div>

                            {dataCharts.pedTB[0] &&
                                <div className="card_bar">
                                    <a className="text_tl">Profundidade máxima e média</a>
                                    <ComposedChart width={650} height={350} data={dataCharts.pedTB}
                                        margin={{ top: 5, right: 20, left: 20, bottom: 5 }}>
                                        <defs>
                                            <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                                                <stop offset="5%" stopColor="#0088FE" stopOpacity={0.8} />
                                                <stop offset="95%" stopColor="#0088FE" stopOpacity={0} />
                                            </linearGradient>
                                        </defs>
                                        <XAxis tickCount={2} />
                                        <YAxis ticks={yAxisProf} interval={0} />
                                        <Legend />
                                        <Area name="Profundidade máxima" type="step" dataKey="value" stroke="#0088FE" fillOpacity={1} fill="url(#colorValue)" />
                                        <Line name="Profundidade média" type="step" dataKey="media" stroke="#FFBB28" dot={false} strokeWidth={2} />
                                    </ComposedChart >
                                </div>
                            }

                            {dataCharts.pedologia[0] &&
                                <div className="card_bar">
                                    <a className="text_tl">Quantidade de pacotes atingidos</a>
                                    <BarChart
                                        width={650}
                                        height={400}
                                        data={dataCharts.pedologia}
                                        margin={{
                                            top: 5,
                                            right: 40,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                        layout="vertical"
                                    >
                                        <XAxis type="number" />
                                        <YAxis dataKey="name" type="category" interval={0} />
                                        <Bar dataKey="value" fill="#82ca9d" label={{ position: 'right' }}>
                                            {dataCharts.pedologia.map((entry, index) => (
                                                <Cell key={`cell-${index}`} />
                                            ))}
                                        </Bar>
                                    </BarChart>
                                </div>
                            }

                        </div>
                    </div>
                }
            </div>
        </div >
    )

};
export default Dashboard;