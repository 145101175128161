import React, { useEffect, useState } from 'react';
import { auth, db } from '../../services/firebaseConnection'
import { onAuthStateChanged, signOut } from 'firebase/auth'
import { ref, set, get, child, remove, update } from "firebase/database";
import { useNavigate } from 'react-router-dom';

import { IoMdExit } from "react-icons/io";
import { BiArrowBack, BiEditAlt, BiTargetLock, BiPaperclip } from "react-icons/bi";
import { AiOutlineAppstoreAdd, AiTwotoneDelete, AiOutlinePlus } from "react-icons/ai";

import EditarFUser from './EditarFUser';
import AddFUser from './AddFUser';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

function EditUser() {

    const dbRef = ref(db);
    const navigate = useNavigate();

    const [infoUser, setInfoUser] = useState("")
    const [projEsc, setProjEsc] = useState("")
    const [editPonto, setEditPonto] = useState(false)
    const [addPonto, setAddPonto] = useState(false)

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                const uid = user.uid;

                get(child(dbRef, 'users/' + uid)).then((snapshot) => {
                    if (snapshot.exists()) {
                        const dados = snapshot.val()
                        setInfoUser(dados)
                    } else {
                        console.log("No data available");
                    }
                })

            }
        });
    }, [])

    async function getProj(r) {

        get(child(dbRef, 'projetos/' + r + '/campo/' + infoUser.uid)).then((snapshot) => {
            if (snapshot.exists()) {
                const dados = snapshot.val()
                dados.projeto = r
                setProjEsc(dados)
                setAddPonto(false)
                setEditPonto(true)
            } else {
                console.log("No data available");
            }
        })


    }

    async function quitUser() {
        signOut(auth).then(() => {
            navigate('/');
        }).catch((error) => {
            // An error happened.
        });
    }

    const DemoPaper = styled(Paper)(({ theme }) => ({
        padding: theme.spacing(0.5),
        ...theme.typography.body2,
        textAlign: 'center',
    }));

    return (
        <div >

            <div style={{
                width: "100%",
                backgroundColor: "#14213d", // Azul escuro
                color: "white",
                padding: "16px",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                zIndex: 50,
            }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <a style={{ fontWeight: '600', fontSize: 20 }}>Portal do Usuário</a>
                    <button className="btn_quit" onClick={quitUser}><IoMdExit />Sair</button>
                </div>

            </div>

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 20 }}>

                {infoUser && projEsc === "" && (
                    <div>
                        <div style={{ marginBottom: 20 }}>
                            <a style={{ fontWeight: '600', fontSize: 18 }}>Selecione o projeto</a>
                        </div>

                        <div style={{ overflowY: "scroll", height: "70vh" }}>

                            {infoUser.projeto.map((item, index) => (
                                <DemoPaper
                                    key={index}
                                    variant="outlined"
                                    style={{
                                        marginBottom: 6,
                                        cursor: "pointer",
                                        marginRight: 10,
                                    }}
                                    onClick={() => getProj(item)}
                                >
                                    <a>{item}</a>
                                </DemoPaper>
                            ))}
                        </div>
                    </div>
                )}


                {editPonto &&
                    <div>
                        <button className='btn_edit' style={{ width: 250, marginBottom: 20 }} onClick={() => setEditPonto(false) || setAddPonto(true)}>
                            <AiOutlinePlus style={{ color: "#fff", fontSize: '20px' }} />
                            <a style={{ color: "#fff", fontSize: '15px', marginLeft: "5px" }}>Adicionar novo ponto</a>
                        </button>

                        <EditarFUser projeto={projEsc.projeto} uid={infoUser.uid} />
                    </div>
                }

                {addPonto &&
                    <div>
                        <button className='btn_edit' style={{ width: 250, marginBottom: 20, backgroundColor: 'black' }} onClick={() => setEditPonto(true) || setAddPonto(false)}>
                            <BiArrowBack style={{ color: "#fff", fontSize: '20px' }} />
                            <a style={{ color: "#fff", fontSize: '15px', marginLeft: "5px" }}>Voltar para edição</a>
                        </button>

                        <AddFUser projeto={projEsc.projeto} uid={infoUser.uid} />
                    </div>
                }
            </div>

        </div>
    )
}

export default EditUser;